body {
    font-family: inglobal;
    background: #000;
}
* {
    box-sizing: border-box;
}
.App {
    min-height: 100vh;
    background: url(/images/dark_wall.png) repeat 0;
}
p {
    margin: 0;
}
.Battle .battle-column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
}
.Battle .battle-column_title {
    color: #ecd19a;
    font-size: 18px;
    margin-bottom: 10px;
}
.Battle label {
    display: block;
    color: #ad835a;
}
.Battle .feik_input {
    width: 13px;
    height: 13px;
    border: 1px solid #ad835a;
    border-radius: 13px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
}
.Battle .feik_input:after {
    content: '';
    width: 9px;
    height: 9px;
    background: #ad835a;
    border-radius: 9px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: none;
}
.Battle input {
    display: none;
}
.Battle input:checked + .feik_input:after {
    display: block;
}
.Battle .battle-button {
    display: block;
    width: 49px;
    height: 56px;
    background: url(/images/carousel-sprite.png) no-repeat -172px 0;
}
.Battle .battle-button:hover {
    background-position: -258px 0;
}
.Battle .battle-button.hide {
    opacity: 0;
}

.wait-block-wrap {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wait-block {
    border: 1px solid #ad835a;
    border-radius: 4px;
    width: 200px;
    margin-left: 30px;
    padding: 20px;
    background: rgba(255,0,0,0.1);
}
.wait-block-name {
    color: #ad835a;
    margin-bottom: 10px;
}
.wait-block-name span {
    margin-left: 5px;
}
.wait-block_mine {
    background: rgba(0,255,0,0.1);
}

header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.05)), url(/images/bg.jpg);
    box-shadow: inset 0 -10px 70px rgba(0, 0, 0, 0.75);
    height: 70vh;
    background-position: 70%;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
}
.Main_logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 534px;
    height: 120px;
    background: url(/images/logo.png) no-repeat center / contain;
}
.Main_logo:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0;
    background: #fff;
    bottom: 17px;
    left: 0;
    box-shadow: 0 0 15px 9px rgba(255,255,255,1);
}
.Main_logo:before {
    content: 'Многопользовательская онлайн игра';
    display: block;
    position: absolute;
    width: 100%;
    bottom: -15px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    left: 0;
    color: #fff;
    font-family: 'Philosopher', sans-serif;
}

.Divider {
    height: 55px;
    margin: -18px -15px;
    position: relative;
    z-index: 1;
}
.Divider:before {
    left: 0;
    margin-right: 82px;
    right: 50%;
    background: url(/images/divider-sprite.png) repeat-x 50% 0;
    content: "";
    height: 55px;
    position: absolute;
    top: 0;
}
.Divider:after {
    left: 50%;
    margin-left: 82px;
    right: 0;
    background: url(/images/divider-sprite.png) repeat-x 50% 0;
    content: "";
    height: 55px;
    position: absolute;
    top: 0;
}
.Divider-rule {
    background: url(/images/divider-sprite.png) repeat-x 50% 100%;
    border: 0;
    height: 55px;
    left: 50%;
    margin: 0 0 0 -82px;
    position: absolute;
    top: 5px;
    width: 164px;
}

.Main_enter {
    background: url(/images/dark_wall.png) repeat 0;
    margin-top: -40px;
}
.Main_enter_wrap {
    width: 1000px;
    margin: 0 auto;
    text-align: center;
    padding: 100px 30px 50px;
}
.Main_enter_wrap h2 {
    text-transform: uppercase;
    color: #ecd19a;
    font-size: 36px;
    line-height: 40px;
    font-family: 'Philosopher', sans-serif;
    text-shadow: 0px 0px 6px #000000;
}
.Main_enter_wrap_desc {
    color: #71675b;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 56px;
    margin-bottom: 56px;
    background: url(/images/blog-separator.png) no-repeat bottom center;
    text-shadow: 0px 0px 6px #000000;
}

.Main_input_enter {
    border-radius: 0px;
    width: 100%;
    padding: 0 15px;
    height: 44px;
    width: 266px;
    list-style: none;
    font-size: 16px;
    text-align: left;
    border: 1px solid #96744a;
    -webkit-box-shadow: inset 0 0px 10px rgba(0, 0, 0, 0.275);
    box-shadow: inset 0 0px 10px rgba(0, 0, 0, 0.275);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    background-color: #29221c;
    border: 1px solid #62351f;
    border-top-color: #734a32;
    color: #ffcb8d;
    outline: none;
    margin-bottom: 20px;
    text-shadow: 0px 0px 6px #000000;
    font-family: inglobal;
}
.Main_input_enter_button {
    display: block;
    text-decoration: none;
    font-size: 18px;
    line-height: 54px;
    width: 266px;
    margin: 0 auto;
    padding: 0 3px;
    position: relative;
    text-align: center;
    text-shadow: 0px 0px 6px #000000;
}
.Main_input_enter_button .button-game-bg-left {
    background: url(/images/button-sprite.png) no-repeat 0 -108px;
    height: 54px;
    left: 0;
    position: absolute;
    top: 0;
    width: 51px;
    z-index: 1;
}
.Main_input_enter_button .button-game-bg-mid {
    background: url(/images/button-sprite.png) repeat-x 0 0;
    color: #f3aa55;
    display: block;
    font-family: 'Philosopher', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 0 42px;
    position: relative;
    text-shadow: 0 0 8px #000000;
    transition: color 0.2s;
}
.Main_input_enter_button .button-game-bg-mid > span {
    position: relative;
    z-index: 1;
}
.Main_input_enter_button .button-game-bg-mid:after {
    background: url(/images/button-sprite.png) repeat-x 0 -54px;
    content: "";
    height: 54px;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s;
    -webkit-transform: translateZ(0);
}
.Main_input_enter_button:hover .button-game-bg-mid {
    color: #ffcb8d;
}
.Main_input_enter_button:hover .button-game-bg-mid:after {
    opacity: 1;
}
.Main_input_enter_button .button-game-bg-right {
    background: url(/images/button-sprite.png) no-repeat 0 -162px;
    height: 54px;
    position: absolute;
    right: 0;
    top: 0;
    width: 51px;
    z-index: 1;
}


.Location {
    color: #fff;
    min-height: 75vh;
    width: 1200px;
    margin: 0 auto;
}
.Location h2 {
    margin: 0;
    padding: 30px 0 50px;
    text-align: center;
    font-size: 32px;
    background: url(/images/blog-separator.png) no-repeat 50% calc(50% + 30px);
    color: #ecd19a;
    font-size: 36px;
    line-height: 40px;
    font-family: 'Philosopher', sans-serif;
}
.Location_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
}
.Person_wrap {
    width: 300px;
}
.Battle {
    width: 400px;
}


footer {
    background: url(/images/dark_leather.png) repeat 0;
    margin-top: -40px;
    padding: 60px 0 40px;
    color: #734a32;
    text-align: center;
    font-size: 18px;
}