.Person {
	display: inline-block;
	min-width: 300px;
	vertical-align: top;
}
.person-name {
	margin-bottom: 10px;
	text-align: center;
	font-size: 18px;
	color: #ad835a;
}
.person-name span {
	margin-left: 5px;
}
.person-live,
.person-mana {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;
}
.person-live-value,
.person-mana-value {
	margin-left: 10px;
	font-size: 12px;
	color: #ad835a;
}
.person-live-line-wrap,
.person-mana-line-wrap {
	width: 100%;
	height: 5px;
	background: #ad835a;
	border-radius: 5px;
	overflow: hidden;
}
.person-live-line,
.person-mana-line {
	width: 100%;
	height: 100%;
	background: #ffb5b5; /* Old browsers */
	background: -moz-linear-gradient(top, #ffb5b5 1%, #ba2525 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ffb5b5 1%,#ba2525 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ffb5b5 1%,#ba2525 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb5b5', endColorstr='#ba2525',GradientType=0 ); /* IE6-9 */
}
.person-mana-line {
	background: #b3b8fc; /* Old browsers */
	background: -moz-linear-gradient(top, #b3b8fc 1%, #3024b7 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #b3b8fc 1%,#3024b7 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #b3b8fc 1%,#3024b7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3b8fc', endColorstr='#3024b7',GradientType=0 ); /* IE6-9 */
}

.person-ikip {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 300px;
}
.person-ikip .person-ikip-left,
.person-ikip .person-ikip-right {
	width: 25%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.person-ikip .person-ikip-center{
	width: 45%;
}
.person-ikip .person-ikip-elem {
    border: 1px solid #62351f;
	border-radius: 4px;
	box-shadow: inset 0 0 3px rgba(0,0,0,0.4);
}
.person-ikip .person-ikip-left .person-ikip-left-helm {
	height: 15%;
	background: #29221c url('/images/wear/helm.png') no-repeat 50% 50% / contain; 
}
.person-ikip .person-ikip-left .person-ikip-left-weapon {
	height: 30%;
	background: #29221c url('/images/wear/weapon.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-left .person-ikip-left-armor {
	height: 30%;
	background: #29221c url('/images/wear/armor.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-left .person-ikip-left-belt {
	height: 15%;
	background: #29221c url('/images/wear/belt.png') no-repeat 50% 50% / contain;
}

.person-ikip .person-ikip-center .person-ikip-center-figure {
	height: 100%;
	background: #29221c url('/images/wear/figure.png') no-repeat 50% 50% / contain;
}

.person-ikip .person-ikip-right .person-ikip-right-earrings {
	height: 15%;
	background: #29221c url('/images/wear/earrings.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-right .person-ikip-right-collar {
	height: 15%;
	background: #29221c url('/images/wear/collar.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-right .person-ikip-right-gloves {
	height: 15%;
	background: #29221c url('/images/wear/gloves.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-right .person-ikip-right-weapon {
	height: 30%;
	background: #29221c url('/images/wear/weapon2.png') no-repeat 50% 50% / contain;
}
.person-ikip .person-ikip-right .person-ikip-right-boots {
	height: 15%;
	background: #29221c url('/images/wear/boots.png') no-repeat 50% 50% / contain;
}
