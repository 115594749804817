.Logs {
	color: #ecd19a;
}
.Logs span {
	font-weight: bold;
}
.Logs-attack, .Logs-block, .Logs-grey  {
	background: rgba(0,255,0,0.1);
	border: 1px solid #ad835a;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 20px;
}
.Logs-block {
	background: rgba(255,0,0,0.1);
}
.Logs-grey {
	background: rgba(220,220,220,0.1);
}